import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

const PageContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-xxl) var(--spacing-lg);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-xxl);
`;

const PageTitle = styled.h1`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-md);
`;

const PageDescription = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xxl);
  
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectCard = styled.div`
  position: relative;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  &:hover img {
    transform: scale(1.05);
  }
`;

const ProjectImageContainer = styled.div`
  height: 300px;
  overflow: hidden;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-normal);
`;

const ProjectDetails = styled.div`
  padding: var(--spacing-md);
  background-color: var(--white);
`;

const ProjectTitle = styled.h3`
  color: var(--navy-blue);
  font-size: 24px;
  margin-bottom: var(--spacing-xs);
`;

const ProjectLocation = styled.p`
  color: var(--gray-700);
  font-size: 16px;
  margin-bottom: var(--spacing-xs);
  font-weight: bold;
`;

const ProjectDescription = styled.p`
  color: var(--gray-600);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: var(--spacing-xs);
`;

const ProjectValue = styled.p`
  color: var(--gray-700);
  font-size: 14px;
  margin-top: var(--spacing-xs);
  font-weight: 500;
`;

interface Project {
  id: string;
  title: string;
  location: string;
  image: string;
  description: string;
  value?: string;
  services?: string[];
  client?: string;
}

interface ProjectCategoryTemplateProps {
  categoryTitle: string;
  categoryDescription: string;
  projects: Project[];
}

const ProjectCategoryTemplate: React.FC<ProjectCategoryTemplateProps> = ({
  categoryTitle,
  categoryDescription,
  projects
}) => {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>{categoryTitle} Projects</PageTitle>
        <PageDescription>
          {categoryDescription}
        </PageDescription>
      </PageHeader>

      <SectionTitle
        title="Featured Projects"
        subtitle="Explore our portfolio of completed projects in this category."
        centered
      />

      <ProjectGrid>
        {projects.map(project => (
          <ProjectCard key={project.id}>
            <ProjectImageContainer>
              <ProjectImage src={project.image} alt={project.title} />
            </ProjectImageContainer>
            <ProjectDetails>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectLocation>{project.location}</ProjectLocation>
              <ProjectDescription>{project.description}</ProjectDescription>
              {project.client && (
                <ProjectDescription>Client: {project.client}</ProjectDescription>
              )}
              {project.value && (
                <ProjectValue>Project Value: {project.value}</ProjectValue>
              )}
            </ProjectDetails>
          </ProjectCard>
        ))}
      </ProjectGrid>
    </PageContainer>
  );
};

export default ProjectCategoryTemplate; 