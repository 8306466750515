import React from 'react';
import styled from 'styled-components';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import projectData from '../data/projectsData.json';

interface BaseProject {
  id: string;
  title: string;
  location: string;
  description: string;
  client?: string;
  value?: string;
  services?: string[];
}

interface ProjectData {
  current: BaseProject[];
  healthcare: BaseProject[];
  municipality: BaseProject[];
  water: BaseProject[];
  transit: BaseProject[];
  other: BaseProject[];
}

const typedProjectData = projectData as ProjectData;

// Current projects as specified by user
const currentProjects = [
  {
    id: "sfgh-service-facility",
    title: "Zuckerberg San Francisco General Hospital - Service Facility Health & Safety Upgrades",
    location: "San Francisco, CA",
    description: "Mechanical, electrical and plumbing cost estimating for M. Lee Corporation",
    client: "SFDPW",
    value: undefined
  },
  {
    id: "sfpuc-westside-facility",
    title: "Westside Facility CM Services As-Needed Contract",
    location: "San Francisco, CA",
    description: "MEP coordination and document controls",
    client: "SFPUC",
    value: undefined
  },
  {
    id: "sfgh-childcare-center",
    title: "Zuckerberg San Francisco General Hospital Childcare Center",
    location: "San Francisco, CA",
    description: "Cost estimating services",
    client: "SFDPW",
    value: undefined
  },
  {
    id: "chinatown-health-center",
    title: "Chinatown Health Center Renovation",
    location: "San Francisco, CA",
    description: "Constructability and peer reviews",
    client: "SFDPW / SFDPH",
    value: undefined
  },
  {
    id: "mission-branch-library",
    title: "Mission Branch Library Renovation",
    location: "San Francisco, CA",
    description: "Cost estimating, scheduling, and project management",
    client: "SFDPW",
    value: undefined
  },
  {
    id: "oceanside-osp3-osp7",
    title: "Oceanside - OSP #3 and OSP #7 Health & Safety Upgrades",
    location: "San Francisco, CA",
    description: "Campus-wide Mechanical Ductwork (OSP #3) and Administrative Building (OSP #7) - Cost estimating",
    client: "SFDPW / SFPUC",
    value: undefined
  },
  {
    id: "southeast-health-center",
    title: "Southeast Health Center Renovation",
    location: "San Francisco, CA",
    description: "Constructability reviews, peer reviews, and scheduling",
    client: "SFDPW / SFDPH",
    value: undefined
  },
  {
    id: "northpoint-admin-building",
    title: "Northpoint Facility - Administration Building Renovation - Building #930",
    location: "San Francisco, CA",
    description: "Health & Safety Upgrades - cost estimating",
    client: "SFDPW / SFPUC",
    value: undefined
  }
];

// Completed projects as specified by user
const completedProjects = [
  {
    id: "southeast-plant-primary",
    title: "Southeast Plant - Primary Treatment Facility (SEP)",
    location: "San Francisco, CA",
    description: "Health & Safety Upgrades - cost estimating",
    client: "SFDPW / SFPUC"
  },
  {
    id: "laguna-honda-water-tank",
    title: "Laguna Honda Hospital Water Tank Replacement",
    location: "San Francisco, CA",
    description: "Construction management",
    client: "SFDPW"
  },
  {
    id: "sfgh-building5-80-90",
    title: "San Francisco General Hospital - Building #5 and Building #80/90",
    location: "San Francisco, CA",
    description: "Seismic, Systems and ADA Upgrades",
    client: "OSHPD",
    value: "$250 Million"
  },
  {
    id: "sfgh-helipad",
    title: "San Francisco General Hospital - Building #5 - Emergency Response Helipad",
    location: "San Francisco, CA",
    description: "Cost estimating, pre-construction planning",
    client: "OSHPD",
    value: "$5 Million"
  },
  {
    id: "sfgh-ada-upgrades",
    title: "San Francisco General Hospital - Building #5 - ADA Upgrades",
    location: "San Francisco, CA",
    description: "Cost Estimating for ADA compliance upgrades",
    client: "OSHPD"
  },
  {
    id: "laguna-honda-dialysis",
    title: "Laguna Honda Hospital Dialysis Center",
    location: "San Francisco, CA",
    description: "Conceptual and schematic cost estimating",
    client: "OSHPD",
    value: "$11 Million"
  },
  {
    id: "kaiser-south-sf",
    title: "Kaiser Permanente - South San Francisco (MOB and Head & Neck Surgery)",
    location: "South San Francisco, CA",
    description: "Cost estimating and contractor estimate reviews",
    client: "OSHPD"
  },
  {
    id: "highland-hospital",
    title: "Highland Hospital Acute Tower Replacement",
    location: "Oakland, CA",
    description: "Project planning, project management, construction management, document control and office engineering",
    client: "OSHPD",
    value: "$660 Million"
  },
  {
    id: "laguna-honda-kitchen-flooring",
    title: "Laguna Honda Hospital - Link Building Kitchen Flooring Replacement",
    location: "San Francisco, CA",
    description: "Cost estimating for kitchen flooring replacement",
    client: "OSHPD",
    value: "$10 Million"
  },
  {
    id: "kaweah-delta",
    title: "Kaweah Delta District Hospital North Expansion",
    location: "Visalia, CA",
    description: "Architectural peer review and document review",
    client: "OSHPD",
    value: "$200 Million"
  },
  {
    id: "davis-grant-medical",
    title: "Davis S. Grant Medical Center, Travis Air Force Base (Phase 1)",
    location: "Fairfield, CA",
    description: "Electrical contract completion and evaluation for surety on contract default",
    value: "$25 Million"
  },
  {
    id: "veterans-hospital",
    title: "Veterans Hospital",
    location: "Palo Alto, CA",
    description: "Tile contract completion and evaluation for surety on contract default"
  },
  {
    id: "eden-hospital",
    title: "Eden Hospital",
    location: "Castro Valley, CA",
    description: "Cost Estimating For Strategic Planning",
    client: "OSHPD",
    value: "$300 Million"
  },
  {
    id: "sfdph-grove-elevator",
    title: "SFDPH Facility - 101 Grove Street Elevator Installation",
    location: "San Francisco, CA",
    description: "Cost Estimating for elevator installation"
  },
  {
    id: "sfdph-silver-avenue",
    title: "SFDPH Silver Avenue Health Center",
    location: "San Francisco, CA",
    description: "Cost Estimating for health center"
  },
  {
    id: "transbay-center",
    title: "Transbay Center & Terminal Project",
    location: "San Francisco, CA",
    description: "Program management - office engineering support",
    value: "$8 Billion"
  },
  {
    id: "clean-water-master-plan",
    title: "SFPUC Clean Water Master Plan",
    location: "San Francisco, CA",
    description: "Program management - planning, site analysis, conceptual cost estimating",
    value: "$11 Billion"
  },
  {
    id: "sfpuc-30-year-planning",
    title: "SFPUC - 30-Year Clean Water Planning Program",
    location: "San Francisco, CA",
    description: "Planning, cost estimating, environmental and neighborhood impact analysis"
  },
  {
    id: "sfpuc-primary-sedimentation",
    title: "SFPUC - Primary Sedimentation Facility Seismic Upgrades - Building 040, 041, 042",
    location: "San Francisco, CA",
    description: "Cost estimating for seismic upgrades"
  },
  {
    id: "sfia-international",
    title: "SFIA - International Terminal Building Refresh - Phase 1",
    location: "San Francisco, CA",
    description: "Administrative support for terminal refresh",
    value: "$500 Million"
  }
];

const PageContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-xxl) var(--spacing-lg);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-xxl);
`;

const PageTitle = styled.h1`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-md);
`;

const PageDescription = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
`;

const SectionContainer = styled.div`
  margin-bottom: var(--spacing-xxl);
`;

const SectionHeader = styled.h2`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-lg);
  text-align: center;
`;

const ProjectList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-lg);
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectItem = styled.li`
  background-color: var(--light-gray);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  h3 {
    color: var(--navy-blue);
    margin-bottom: var(--spacing-sm);
  }
  
  p.location {
    color: var(--dark-gray);
    font-weight: bold;
    margin-bottom: var(--spacing-xs);
  }
  
  p.description {
    color: var(--dark-gray);
  }
  
  p.value {
    color: var(--dark-gray);
    margin-top: var(--spacing-xs);
  }

  p.client {
    color: var(--dark-gray);
    margin-top: var(--spacing-xs);
    font-style: italic;
  }
`;

const CurrentProjects: React.FC = () => {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Projects</PageTitle>
        <PageDescription>
          Explore our diverse portfolio of current and completed projects, showcasing our expertise across various sectors including healthcare, water infrastructure, transit facilities, and more.
        </PageDescription>
      </PageHeader>

      <SectionContainer>
        <SectionHeader>Current Projects</SectionHeader>
        <ProjectList>
          {currentProjects.map(project => (
            <ProjectItem key={project.id}>
              <h3>{project.title}</h3>
              <p className="location">{project.location}</p>
              <p className="description">{project.description}</p>
              {project.client && <p className="client">Client: {project.client}</p>}
              {project.value && <p className="value">Value: {project.value}</p>}
            </ProjectItem>
          ))}
        </ProjectList>
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>Completed Projects</SectionHeader>
        <ProjectList>
          {completedProjects.map(project => (
            <ProjectItem key={project.id}>
              <h3>{project.title}</h3>
              <p className="location">{project.location}</p>
              <p className="description">{project.description}</p>
              {project.client && <p className="client">Client: {project.client}</p>}
              {project.value && <p className="value">Value: {project.value}</p>}
            </ProjectItem>
          ))}
        </ProjectList>
      </SectionContainer>
    </PageContainer>
  );
};

export default CurrentProjects; 