import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

interface MainLayoutProps {
  children: React.ReactNode;
}

const Main = styled.main`
  min-height: calc(100vh - 160px); /* Adjust based on header and footer height */
`;

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default MainLayout; 