import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';

interface ServiceCardProps {
  id: string;
  title: string;
  description: string;
  icon?: React.ReactNode;
  className?: string;
  showLearnMore?: boolean;
}

const ServiceCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-8px);
  }
`;

const IconContainer = styled.div`
  color: var(--gold);
  font-size: 48px;
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`;

const Title = styled.h3`
  margin-bottom: var(--spacing-md);
  color: var(--navy-blue);
  text-align: center;
`;

const Description = styled.p`
  color: var(--dark-gray);
  margin-bottom: var(--spacing-lg);
  flex-grow: 1;
`;

const StyledLink = styled(Link)`
  align-self: center;
  text-decoration: none;
  color: var(--navy-blue);
  font-weight: 600;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--gold);
    transition: width var(--transition-normal);
  }
  
  &:hover::after {
    width: 100%;
  }
`;

const ServiceCard: React.FC<ServiceCardProps> = ({
  id,
  title,
  description,
  icon,
  className,
  showLearnMore = true,
}) => {
  return (
    <ServiceCardContainer hoverEffect className={className}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <Title>{title}</Title>
      <Description>{description}</Description>
      {showLearnMore && <StyledLink to={`/services#${id}`}>Learn More</StyledLink>}
    </ServiceCardContainer>
  );
};

export default ServiceCard; 