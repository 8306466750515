import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.jpg';
import hiDevLogo from '../../assets/hidev_logo.png';

interface FooterProps {
  className?: string;
}

const FooterContainer = styled.footer`
  background-color: var(--navy-blue);
  color: var(--white);
  padding: var(--spacing-xl) 0 var(--spacing-lg);
`;

const FooterContent = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const FooterSection = styled.div`
  margin-bottom: var(--spacing-lg);
`;

const FooterHeading = styled.h3`
  color: var(--gold);
  font-size: 18px;
  margin-bottom: var(--spacing-md);
  font-weight: 600;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterListItem = styled.li`
  margin-bottom: var(--spacing-sm);
`;

const FooterLink = styled(Link)`
  color: var(--white);
  text-decoration: none;
  transition: color var(--transition-fast);
  font-size: 14px;
  opacity: 0.9;
  
  &:hover {
    color: var(--gold);
    opacity: 1;
  }
`;

const FooterText = styled.p`
  margin-bottom: var(--spacing-sm);
  font-size: 14px;
  opacity: 0.9;
  line-height: 1.6;
`;

const FooterBottom = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-lg) 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xl);
  
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-lg);
  }
`;

const BottomNav = styled.nav`
  display: flex;
  gap: var(--spacing-lg);
  flex-grow: 1;
  justify-content: center;
  
  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: var(--spacing-md);
  }
`;

const BottomNavLink = styled(Link)`
  color: var(--white);
  text-decoration: none;
  font-size: 14px;
  opacity: 0.9;
  transition: color var(--transition-fast);
  
  &:hover {
    color: var(--gold);
    opacity: 1;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  height: 70px;
  overflow: hidden;
`;

const Logo = styled.img`
  height: 75px;
  transform: scale(1.1);
  width: auto;
  object-fit: cover;
  object-position: center;
`;

const HiDevLogo = styled.img`
  height: 40px;
  width: auto;
`;

const CopyrightText = styled.p`
  font-size: var(--small-desktop);
  opacity: 0.8;
  text-align: center;
  margin-top: var(--spacing-lg);
  padding: 0 var(--spacing-lg);
  
  @media (max-width: 767px) {
    margin-bottom: var(--spacing-md);
  }
`;

const Footer: React.FC<FooterProps> = ({ className }) => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer className={className}>
      <FooterContent>
        <FooterSection>
          <FooterHeading>About CM West</FooterHeading>
          <FooterText>
            Construction Management West, Inc. provides comprehensive construction management services for complex projects. Our seasoned professionals are highly sensitive to project cost and time issues, enabling our clients to receive maximum benefit from state-of-the-art project control methods.
          </FooterText>
          <FooterText>
            Since 1987, we have maintained a reputation for delivering uniquely personalized service and consistently useful solutions for our clientele.
          </FooterText>
        </FooterSection>

        <FooterSection>
          <FooterHeading>Services</FooterHeading>
          <FooterList>
            <FooterListItem>
              <FooterLink to="/services#program-management">Program Management</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#pre-construction">Pre-Construction Services</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#claims-dispute">Claims & Dispute Resolution</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#construction-management">Construction Management</FooterLink>
            </FooterListItem>
          </FooterList>
        </FooterSection>

        <FooterSection>
          <FooterHeading>Projects</FooterHeading>
          <FooterList>
            <FooterListItem>
              <FooterLink to="/projects/healthcare">Healthcare</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/projects/educational">Educational</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/projects/water-projects">Water Facilities</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/projects/municipality-facilities">Municipality Facilities</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/projects/transit-facilities">Transit Facilities</FooterLink>
            </FooterListItem>
          </FooterList>
        </FooterSection>

        <FooterSection>
          <FooterHeading>Contact Us</FooterHeading>
          <FooterText>
            930 Montgomery St # 200<br />
            San Francisco, CA 94133
          </FooterText>
          <FooterText>
            Phone: (415) 397-6102<br />
            Email: cmwest@flash.net
          </FooterText>
        </FooterSection>
      </FooterContent>

      <FooterBottom>
        <LogoContainer>
          <Link to="/">
            <Logo src={logo} alt="CMWest - Construction Management West, Inc." />
          </Link>
        </LogoContainer>

        <BottomNav>
          <BottomNavLink to="/">Home</BottomNavLink>
          <BottomNavLink to="/services">Services</BottomNavLink>
          <BottomNavLink to="/projects">Projects</BottomNavLink>
          <BottomNavLink to="/about">About</BottomNavLink>
          <BottomNavLink to="/contact">Contact</BottomNavLink>
        </BottomNav>

        <LogoContainer>
          <a href="https://hidevmobile.com" target="_blank" rel="noopener noreferrer">
            <HiDevLogo src={hiDevLogo} alt="Hi Dev Mobile" />
          </a>
        </LogoContainer>
      </FooterBottom>

      <CopyrightText>
        &copy; {currentYear} Construction Management West, Inc. All rights reserved.
      </CopyrightText>
    </FooterContainer>
  );
};

export default Footer; 