import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';

interface HeroProps {
  slides: {
    image: string;
    title: string;
    subtitle?: string;
  }[];
  className?: string;
}

const HeroContainer = styled.section`
  position: relative;
  height: 70vh;
  overflow: hidden;
  
  @media (max-width: 767px) {
    height: 50vh;
  }
`;

const HeroSlide = styled.div<{ $isActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => props.$isActive ? 1 : 0};
  transition: opacity 1s ease-in-out;
  z-index: ${props => props.$isActive ? 1 : 0};
`;

const HeroImage = styled.div<{ $backgroundImage: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.$backgroundImage});
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  width: 80%;
  max-width: 800px;
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  margin-bottom: var(--spacing-md);
  color: white;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
  
  @media (max-width: 767px) {
    font-size: 32px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 20px;
  margin-bottom: var(--spacing-lg);
  color: white;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
  
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const SlideIndicators = styled.div`
  position: absolute;
  bottom: var(--spacing-lg);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: var(--spacing-sm);
  z-index: 2;
`;

const SlideIndicator = styled.button<{ $isActive: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.$isActive ? 'var(--gold)' : 'var(--white)'};
  border: none;
  cursor: pointer;
  opacity: ${props => props.$isActive ? 1 : 0.6};
  transition: all var(--transition-fast);
  
  &:hover {
    opacity: 1;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Hero: React.FC<HeroProps> = ({ slides, className }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Auto-rotate slides
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prev => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <HeroContainer className={className}>
      {slides.map((slide, index) => (
        <HeroSlide key={index} $isActive={index === currentSlide}>
          <HeroImage $backgroundImage={slide.image} />
        </HeroSlide>
      ))}

      <HeroContent>
        <HeroTitle>{slides[currentSlide].title}</HeroTitle>
        {slides[currentSlide].subtitle && (
          <HeroSubtitle>{slides[currentSlide].subtitle}</HeroSubtitle>
        )}
        <StyledLink to="/contact">
          <Button>Contact Us</Button>
        </StyledLink>
      </HeroContent>

      <SlideIndicators>
        {slides.map((_, index) => (
          <SlideIndicator
            key={index}
            $isActive={index === currentSlide}
            onClick={() => goToSlide(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </SlideIndicators>
    </HeroContainer>
  );
};

export default Hero; 