import React from 'react';
import ProjectCategoryTemplate from './ProjectCategoryTemplate';

// Import educational project images
import cityCollegeChinatown from '../../assets/CM West Marketing - Part 3 Educational/City College - Chinatown  North Beach Campus 1.jpg';
import westPortalElementary from '../../assets/CM West Marketing - Part 3 Educational/West Portal Elementary School, SF (1).jpg';
import cityCollegeJointUse from '../../assets/CM West Marketing - Part 3 Educational/SF City College Joint-Use Building 1.jpg';
import newMissionCampus from '../../assets/CM West Marketing - Part 3 Educational/New Mission Campus, CCSF.JPG';

const educationalProjects = [
  {
    id: 'ccsf-chinatown',
    title: 'City College of San Francisco, Chinatown/North Beach Campus',
    location: 'San Francisco, CA',
    image: cityCollegeChinatown,
    description: 'CM/GC new campus facility - construction management and project controls',
    value: '$70 Million',
    services: ['Construction management', 'Project controls']
  },
  {
    id: 'vbn-phau-joint',
    title: 'VBN-Phau Architects - Joint Use Facility, Balboa Reservoir Development Project',
    location: 'City College of San Francisco, CA',
    image: cityCollegeJointUse,
    description: 'Multi-Use Facility - cost estimating',
    value: '$55 Million',
    services: ['Cost estimating']
  },
  {
    id: 'ccsf-mission',
    title: 'Mission Campus Expansion, City College of San Francisco',
    location: 'San Francisco, CA',
    image: newMissionCampus,
    description: 'Project management and scheduling',
    services: ['Project management', 'Scheduling']
  },
  {
    id: 'sfusd-school',
    title: 'SFUSD - School Renovation / ADA Upgrades',
    location: 'San Francisco, CA',
    image: westPortalElementary,
    description: 'Construction management for school renovations',
    services: ['Construction management']
  }
];

const Educational: React.FC = () => {
  return (
    <ProjectCategoryTemplate
      categoryTitle="Educational"
      categoryDescription="CM West provides construction management services for educational facilities, including K-12 schools, community colleges, and university buildings. We understand the unique requirements of educational environments and deliver projects that support learning and academic excellence."
      projects={educationalProjects}
    />
  );
};

export default Educational; 