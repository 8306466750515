import React from 'react';
import styled from 'styled-components';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import sfiaTerminal2 from '../assets/CM West Marketing - Part 5 Transit Facilities/CM West SFIA Terminal 2.jpg';

const PageContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-xxl) var(--spacing-lg);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-xxl);
`;

const PageTitle = styled.h1`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-md);
`;

const PageDescription = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: var(--spacing-xxl);
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  align-items: center;
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  p {
    margin-bottom: var(--spacing-md);
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 767px) {
    order: -1;
    margin-bottom: var(--spacing-lg);
  }
`;

const About: React.FC = () => {
  return (
    <PageContainer>
      <div id="top"></div>
      <PageHeader>
        <PageTitle>About Us</PageTitle>
        <PageDescription>
          Construction Management West, Inc. is a leading construction management firm with a commitment to excellence and client satisfaction since 1987.
        </PageDescription>
      </PageHeader>

      <Section>
        <SectionTitle title="Our Story" />
        <ContentGrid>
          <Content>
            <p>
              Construction Management West, Inc. (CM West) was founded in 1987 in San Francisco by Lionel J. Recio, AIA who is both a Registered Architect and Licensed General Contractor. Having a professional background and working knowledge in both design and construction, Mr. Recio has consistently tailored the company's services to best meet client needs.
            </p>
            <p>
              Over the past thirty-five (35+) years this small construction consulting enterprise has developed a reputation for delivering a uniquely personalized service. This has resulted in a consistently useful product for its clientele. The company prides itself on being flexible and utilitarian – able to adapt quickly to the evolving requirements that project owner's often times find themselves being faced with.
            </p>
            <p>
              The Company is comprised of seasoned professionals who pragmatically apply technical versatility and sound business judgment to help clients manage the many challenges and complexities common to the construction industry today. The Company has assisted many clientele over the years in either preventing or mitigating losses while controlling and minimizing project risks. The firm has developed a reputation for responsiveness and reliability - the ability to gets things done efficiently and effectively.
            </p>
          </Content>
          <Image
            src="https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
            alt="Construction site"
          />
        </ContentGrid>
      </Section>

      <Section>
        <SectionTitle title="Our Team" />
        <ContentGrid>
          <Image
            src="https://images.unsplash.com/photo-1504307651254-35680f356dfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80"
            alt="Construction team"
          />
          <Content>
            <p>
              Our select staff is composed of experienced architects, engineers and construction professionals who constitute a resourceful and balanced management team with diverse design and construction experience. Our staff's value comes from their extensive hands-on experience alongside their academic credentials.
            </p>
            <p>
              Our team members are progressive, pro-active and solution-oriented in our project planning, execution and delivery. By employing current "state-of-the-art" technology we are able to provide our clientele with the benefits of today's time sensitive and cost efficient workplace.
            </p>
            <p>
              CM West delivers services promptly and efficiently, avoiding excessive overhead and bureaucracy. Working for over three and a half decades in the San Francisco Bay Area construction environment, the firm is knowledgeable about local industry trends, construction cost fluctuations, labor organizational issues, current supply chain trends and the general procurement of contractors, suppliers and materials.
            </p>
          </Content>
        </ContentGrid>
      </Section>

      <Section>
        <SectionTitle title="Our Expertise" />
        <ContentGrid>
          <Content>
            <p>
              Although the majority of CM West's projects have been predominantly building-type facilities (i.e., airports, hospitals, libraries, educational, correctional and convention facilities) we have also successfully performed project management services on civil and infrastructure projects that include underground utilities, highways, pump stations, reservoirs, industrial and plant facilities.
            </p>
            <p>
              The professional services that the company offers include:
            </p>
            <ul>
              <li>Program and project management</li>
              <li>Scheduling</li>
              <li>Cost estimating</li>
              <li>Constructability reviews</li>
              <li>Value engineering</li>
              <li>Claims analysis</li>
              <li>Dispute resolution</li>
              <li>Quality control inspections</li>
              <li>Procurement services</li>
              <li>Pre-construction services including program management and planning</li>
              <li>Contractor procurement and bid processing</li>
            </ul>
            <p>
              A key element in all our projects is our commitment to actively listening to clients and responding effectively. CM West's experienced construction team prioritizes cost and time efficiency, ensuring clients benefit from advanced project control methods and operations.
            </p>
          </Content>
          <Image
            src="https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1931&q=80"
            alt="Construction project"
          />
        </ContentGrid>
      </Section>

      <Section>
        <SectionTitle title="Our Clients" />
        <ContentGrid>
          <Image
            src={sfiaTerminal2}
            alt="San Francisco International Airport Terminal 2 - Client Project"
          />
          <Content>
            <p>
              The company's client-base primarily consists of local municipalities and public agencies, construction managers, architects and engineers. CM West is currently performing work at local airport facilities, local public works departments, local port authorities, local non-profit organizations, local school districts, and is working with a number of local consultants in a supportive capacity.
            </p>
            <p>
              Since inception, the firm has remained a very personalized full-service construction management organization providing services for clientele such as municipalities, public agencies, real estate developers, surety companies, attorneys, design professionals, construction contractors and consultants.
            </p>
            <p>
              Our success has been due largely to our ability to listen carefully to our client's concerns and needs. Only then are we able to respond with the appropriate staffing, technical or management solution. We are dedicated from start to finish to every project undertaken - large or small.
            </p>
          </Content>
        </ContentGrid>
      </Section>

      <Section>
        <SectionTitle title="Our Track Record" centered />
        <p style={{ textAlign: 'center', marginBottom: 'var(--spacing-xl)' }}>
          The Company is proud of the fact that the firm has a sterling track record of never having any type of litigation, civil action, legal demand or claim ever filed against the Company in all of its years of business in both the public and private works arenas.
        </p>
        <p style={{ textAlign: 'center' }}>
          This is not only a testament to the firm's ability to document projects accurately and thoroughly, but also speaks to the Company's core philosophy of dealing with all parties involved in the construction project fairly and equitably.
        </p>
      </Section>

      <Section>
        <SectionTitle title="Certifications & Financial Standing" centered />
        <p style={{ textAlign: 'center', marginBottom: 'var(--spacing-xl)' }}>
          As a small, minority-owned local business, CM West is certified with a number of recognized agencies and maintains a strong financial position.
        </p>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 'var(--spacing-lg)' }}>
          <div>
            <h3>Certifications</h3>
            <p>The firm is certified with numerous local agencies including:</p>
            <ul>
              <li>Caltrans (CUCP)</li>
              <li>Regional Transit Coordinating Council (RTCC)</li>
              <li>Human Rights Commission of San Francisco</li>
              <li>Port of Oakland</li>
              <li>City of Oakland</li>
              <li>Contracts Monitoring Division of San Francisco (CMD)</li>
              <li>Alameda County (SLEB)</li>
              <li>Caltrans (State of California – SMBE)</li>
            </ul>
            <p>Additionally, the Company is 100% Hispanic-owned and controlled.</p>
          </div>
          <div>
            <h3>Financial Standing</h3>
            <p>The company is financially solvent never having any civil action filed against the firm in the thirty-five plus years of doing business. The Company carries $1Million in professional liability coverage.</p>
          </div>
        </div>
      </Section>

      <Section>
        <SectionTitle title="Our Values" centered />
        <p style={{ textAlign: 'center', marginBottom: 'var(--spacing-xl)' }}>
          Our core values guide everything we do at Construction Management West, Inc.
        </p>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 'var(--spacing-lg)' }}>
          <div style={{ textAlign: 'center' }}>
            <h3>Excellence</h3>
            <p>We strive for excellence in every aspect of our work, from planning and execution to client service and follow-up.</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3>Integrity</h3>
            <p>We conduct our business with the highest level of integrity, honesty, and ethical standards.</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3>Personalized Service</h3>
            <p>We deliver uniquely personalized service tailored to each client's specific needs and project requirements.</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3>Flexibility</h3>
            <p>We pride ourselves on being flexible and adaptable, able to quickly adjust to evolving project requirements.</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3>Efficiency</h3>
            <p>We deliver services timely and efficiently without the burden of extensive overhead or unnecessary administration.</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3>Local Expertise</h3>
            <p>We maintain deep knowledge of the San Francisco Bay Area construction environment and market trends.</p>
          </div>
        </div>
      </Section>

      <Section>
        <SectionTitle title="Our Locations" centered />
        <p style={{ textAlign: 'center', marginBottom: 'var(--spacing-xl)' }}>
          CM West maintains offices throughout California to serve clients across the state.
        </p>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 'var(--spacing-xl)', marginTop: 'var(--spacing-xl)', textAlign: 'center' }}>
          <div>
            <h3>San Francisco Office</h3>
            <p>930 Montgomery Street, Suite 200<br />San Francisco, CA 94133</p>
            <p>Telephone: (415) 397-6102<br />Facsimile: (415) 397-4441</p>
          </div>
          <div>
            <h3>Oakland Office</h3>
            <p>364-14th Street, Suite 201<br />Oakland, CA 94067</p>
            <p>Telephone: (510) 834-7100<br />Facsimile: (510) 834-7101</p>
          </div>
        </div>

        <p style={{ textAlign: 'center', marginTop: 'var(--spacing-xl)' }}>
          Email for general inquiries: cmwest@flash.net
        </p>
      </Section>
    </PageContainer>
  );
};

export default About; 