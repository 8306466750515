import { createGlobalStyle } from 'styled-components';

// Note: Add this link to your public/index.html instead:
// <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet">

const GlobalStyles = createGlobalStyle`
  /* CSS Reset and Variables */
  :root {
    /* Primary Colors */
    --navy-blue: #1A2A4A;
    --gold: #D4AF37;
    
    /* Secondary Colors */
    --light-gray: #F5F5F5;
    --dark-gray: #333333;
    --white: #FFFFFF;
    
    /* Accent Colors */
    --teal: #008080;
    --light-blue: #4A90E2;
    
    /* Font Sizes */
    --h1-desktop: 36px;
    --h1-mobile: 28px;
    --h2-desktop: 28px;
    --h2-mobile: 24px;
    --h3-desktop: 22px;
    --h3-mobile: 20px;
    --body-desktop: 16px;
    --body-mobile: 14px;
    --small-desktop: 14px;
    --small-mobile: 12px;
    
    /* Spacing */
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 48px;
    
    /* Border Radius */
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    
    /* Transitions */
    --transition-fast: 0.2s ease;
    --transition-normal: 0.3s ease;
    
    /* Max Width */
    --max-width: 1200px;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html, body {
    font-family: 'Open Sans', sans-serif;
    font-size: var(--body-desktop);
    line-height: 1.5;
    color: var(--dark-gray);
    background-color: var(--white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: var(--spacing-md);
    color: var(--navy-blue);
  }
  
  h1 {
    font-size: var(--h1-desktop);
  }
  
  h2 {
    font-size: var(--h2-desktop);
  }
  
  h3 {
    font-size: var(--h3-desktop);
  }
  
  p {
    margin-bottom: var(--spacing-md);
  }
  
  a {
    color: var(--light-blue);
    text-decoration: none;
    transition: color var(--transition-fast);
    
    &:hover {
      color: var(--navy-blue);
    }
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  button {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
  }
  
  ul, ol {
    list-style-position: inside;
    margin-bottom: var(--spacing-md);
  }
  
  .container {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 var(--spacing-lg);
  }
  
  /* Responsive adjustments */
  @media (max-width: 767px) {
    h1 {
      font-size: var(--h1-mobile);
    }
    
    h2 {
      font-size: var(--h2-mobile);
    }
    
    h3 {
      font-size: var(--h3-mobile);
    }
    
    body {
      font-size: var(--body-mobile);
    }
  }
`;

export default GlobalStyles; 