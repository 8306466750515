import React from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

const PageContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-xxl) var(--spacing-lg);
`;

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: var(--navy-blue);
  font-weight: 600;
  margin-bottom: var(--spacing-lg);
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
  
  svg {
    margin-right: var(--spacing-xs);
  }
`;

const HeroImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-lg);
`;

const ProjectHeader = styled.div`
  margin-bottom: var(--spacing-xl);
`;

const ProjectTitle = styled.h1`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-sm);
`;

const ProjectLocation = styled.h3`
  color: var(--dark-gray);
  font-weight: bold;
  margin-bottom: var(--spacing-md);
`;

const ProjectDetails = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xxl);
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectDescription = styled.div`
  p {
    margin-bottom: var(--spacing-md);
    line-height: 1.6;
  }
`;

const ProjectSidebar = styled.div`
  background-color: var(--light-gray);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-sm);
  height: fit-content;
`;

const ProjectInfo = styled.div`
  margin-bottom: var(--spacing-md);
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoLabel = styled.h4`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-xs);
`;

const InfoValue = styled.p`
  margin: 0;
`;

const ProjectGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-xxl);
  
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: var(--border-radius-sm);
`;

const ProjectDetail: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  // This would be replaced with actual data fetching based on projectId
  const project = {
    id: projectId,
    title: 'Project Title',
    location: 'Project Location',
    image: 'https://via.placeholder.com/1200x600',
    description: 'Detailed project description would go here. This would include information about the scope of work, challenges, and solutions implemented during the construction management process.',
    client: 'Client Name',
    completionDate: 'January 2023',
    value: '$10 million',
    services: 'Construction Management, Project Planning',
    gallery: [
      'https://via.placeholder.com/600x400',
      'https://via.placeholder.com/600x400',
      'https://via.placeholder.com/600x400',
    ]
  };

  return (
    <PageContainer>
      <BackLink to="/projects">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M19 12H5M12 19l-7-7 7-7" />
        </svg>
        Back to Projects
      </BackLink>

      <HeroImage src={project.image} alt={project.title} />

      <ProjectHeader>
        <ProjectTitle>{project.title}</ProjectTitle>
        <ProjectLocation>{project.location}</ProjectLocation>
      </ProjectHeader>

      <ProjectDetails>
        <ProjectDescription>
          <p>{project.description}</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Vivamus hendrerit arcu sed erat molestie vehicula. Sed auctor neque eu tellus rhoncus ut eleifend nibh porttitor. Ut in nulla enim. Phasellus molestie magna non est bibendum non venenatis nisl tempor.</p>
        </ProjectDescription>

        <ProjectSidebar>
          <ProjectInfo>
            <InfoLabel>Client</InfoLabel>
            <InfoValue>{project.client}</InfoValue>
          </ProjectInfo>
          <ProjectInfo>
            <InfoLabel>Completion Date</InfoLabel>
            <InfoValue>{project.completionDate}</InfoValue>
          </ProjectInfo>
          <ProjectInfo>
            <InfoLabel>Project Value</InfoLabel>
            <InfoValue>{project.value}</InfoValue>
          </ProjectInfo>
          <ProjectInfo>
            <InfoLabel>Services Provided</InfoLabel>
            <InfoValue>{project.services}</InfoValue>
          </ProjectInfo>
        </ProjectSidebar>
      </ProjectDetails>

      <SectionTitle
        title="Project Gallery"
        subtitle="Additional images from this project"
        centered
      />

      <ProjectGallery>
        {project.gallery.map((image, index) => (
          <GalleryImage key={index} src={image} alt={`${project.title} - Gallery Image ${index + 1}`} />
        ))}
      </ProjectGallery>
    </PageContainer>
  );
};

export default ProjectDetail; 