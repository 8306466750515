import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.jpg';

interface HeaderProps {
  className?: string;
}

const HeaderContainer = styled.header`
  background-color: var(--navy-blue);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  border-bottom: 3px solid var(--gold);
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md) var(--spacing-lg);
  max-width: var(--max-width);
  margin: 0 auto;
`;

const Logo = styled.img`
  height: 90px;
  transform: scale(1.2);
  width: auto;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.3);
  }
  
  @media (max-width: 767px) {
    height: 60px;
    transform: scale(1);
    
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  height: 85px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  
  @media (max-width: 767px) {
    height: 60px;
    padding: var(--spacing-xs) var(--spacing-sm);
  }
`;

const ExternalLink = styled.a`
  margin-left: var(--spacing-lg);
  display: flex;
  align-items: center;
  
  @media (max-width: 767px) {
    display: none;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-sm);
  background: linear-gradient(to bottom, rgba(0,0,0,0.2), transparent);
  border: 1px solid rgba(255, 215, 0, 0.3);
  
  @media (max-width: 767px) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin: 0 var(--spacing-md);
  position: relative;
  
  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 25%;
    right: calc(-1 * var(--spacing-md) / 2);
    height: 50%;
    width: 1px;
    background-color: rgba(255, 215, 0, 0.3);
  }
`;

const NavLink = styled(Link) <{ $isActive?: boolean }>`
  color: ${props => props.$isActive ? 'var(--gold)' : 'var(--white)'};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-decoration: none;
  padding: var(--spacing-sm) 0;
  position: relative;
  transition: color var(--transition-normal);
  
  &:hover {
    color: var(--gold);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${props => props.$isActive ? '100%' : '0'};
    height: 3px;
    background-color: var(--gold);
    transition: width var(--transition-normal);
  }
  
  &:hover::after {
    width: 100%;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: var(--navy-blue);
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-sm) 0;
  top: 100%;
  left: 0;
  border: 1px solid var(--gold);
  border-top: 3px solid var(--gold);
`;

const DropdownItem = styled(Link)`
  color: var(--white);
  padding: var(--spacing-sm) var(--spacing-md);
  text-decoration: none;
  display: block;
  font-family: 'Montserrat', sans-serif;
  
  &:hover {
    background-color: var(--dark-gray);
    color: var(--white);
  }
`;

const Dropdown = styled.div`
  position: relative;
  
  &:hover ${DropdownContent} {
    display: block;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: 2px solid var(--gold);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: var(--gold);
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: var(--gold);
    color: var(--navy-blue);
  }
  
  @media (max-width: 767px) {
    display: flex;
  }
`;

const MobileMenu = styled.div<{ $isOpen: boolean }>`
  display: ${props => props.$isOpen ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--navy-blue);
  z-index: 200;
  padding: var(--spacing-lg);
`;

const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xl);
`;

const MobileMenuClose = styled.button`
  background: none;
  border: none;
  color: var(--white);
  font-size: 24px;
  cursor: pointer;
`;

const MobileNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MobileNavItem = styled.li`
  margin-bottom: var(--spacing-lg);
`;

const MobileNavLink = styled(Link)`
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: var(--h3-mobile);
  text-decoration: none;
  display: block;
`;

const MobileDropdownButton = styled.button`
  background: none;
  border: none;
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: var(--h3-mobile);
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

const MobileDropdownContent = styled.div<{ $isOpen: boolean }>`
  display: ${props => props.$isOpen ? 'block' : 'none'};
  margin-top: var(--spacing-md);
  padding-left: var(--spacing-lg);
`;

const MobileDropdownItem = styled(Link)`
  color: var(--white);
  opacity: 0.8;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  display: block;
  margin-bottom: var(--spacing-md);
  
  &:hover {
    opacity: 1;
  }
`;

const Header: React.FC<HeaderProps> = ({ className }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const location = useLocation();

  // Close mobile menu when route changes
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  // Prevent scrolling when mobile menu is open
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [mobileMenuOpen]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleMobileDropdown = () => {
    setMobileDropdownOpen(!mobileDropdownOpen);
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <HeaderContainer className={className}>
      <HeaderContent>
        <LogoContainer>
          <Link to="/">
            <Logo src={logo} alt="CM West Logo" />
          </Link>
        </LogoContainer>

        <Nav>
          <NavList>
            <NavItem>
              <NavLink to="/" $isActive={isActive('/')}>Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/services" $isActive={isActive('/services')}>Services</NavLink>
            </NavItem>
            <NavItem>
              <Dropdown>
                <NavLink to="/projects" $isActive={isActive('/projects') || location.pathname.startsWith('/projects/')}>
                  Projects
                </NavLink>
                <DropdownContent>
                  <DropdownItem to="/projects/healthcare">Healthcare</DropdownItem>
                  <DropdownItem to="/projects/educational">Educational</DropdownItem>
                  <DropdownItem to="/projects/water-projects">Water Facilities</DropdownItem>
                  <DropdownItem to="/projects/municipality-facilities">Municipality Facilities</DropdownItem>
                  <DropdownItem to="/projects/transit-facilities">Transit Facilities</DropdownItem>
                </DropdownContent>
              </Dropdown>
            </NavItem>
            <NavItem>
              <NavLink to="/current-projects" $isActive={isActive('/current-projects')}>Current Projects</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about" $isActive={isActive('/about')}>About Us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/contact" $isActive={isActive('/contact')}>Contact Us</NavLink>
            </NavItem>
          </NavList>
        </Nav>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MobileMenuButton onClick={toggleMobileMenu}>
            ☰
          </MobileMenuButton>
        </div>
      </HeaderContent>

      <MobileMenu $isOpen={mobileMenuOpen}>
        <MobileMenuHeader>
          <Logo src={logo} alt="CMWest - Construction Management West, Inc." />
          <MobileMenuClose onClick={toggleMobileMenu}>✕</MobileMenuClose>
        </MobileMenuHeader>

        <MobileNavList>
          <MobileNavItem>
            <MobileNavLink to="/">Home</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/services">Services</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileDropdownButton onClick={toggleMobileDropdown}>
              Projects
              <span>{mobileDropdownOpen ? '▲' : '▼'}</span>
            </MobileDropdownButton>
            <MobileDropdownContent $isOpen={mobileDropdownOpen}>
              <MobileDropdownItem to="/projects">All Projects</MobileDropdownItem>
              <MobileDropdownItem to="/projects/healthcare">Healthcare</MobileDropdownItem>
              <MobileDropdownItem to="/projects/educational">Educational</MobileDropdownItem>
              <MobileDropdownItem to="/projects/water-projects">Water Facilities</MobileDropdownItem>
              <MobileDropdownItem to="/projects/municipality-facilities">Municipality Facilities</MobileDropdownItem>
              <MobileDropdownItem to="/projects/transit-facilities">Transit Facilities</MobileDropdownItem>
            </MobileDropdownContent>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/current-projects">Current Projects</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/about">About Us</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/contact">Contact Us</MobileNavLink>
          </MobileNavItem>
        </MobileNavList>
      </MobileMenu>
    </HeaderContainer>
  );
};

export default Header; 