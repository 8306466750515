import React from 'react';
import ProjectCategoryTemplate from './ProjectCategoryTemplate';

// Import all available transit facility images
import centralSubway from '../../assets/CM West Marketing - Part 5 Transit Facilities/CM West - Central Subway Tunnel.jpg';
import sfiaTerminal2 from '../../assets/CM West Marketing - Part 5 Transit Facilities/CM West SFIA Terminal 2.jpg';
import boardingAreaA from '../../assets/CM West Marketing - Part 5 Transit Facilities/boarding-area-a-concourse.jpg';
import sfiaSecuritySystems from '../../assets/CM West Marketing - Part 5 Transit Facilities/sfia-security-systems.jpg';
import transbayTerminal from '../../assets/CM West Marketing - Part 5 Transit Facilities/TransbayTerminalConstruction_PChang_0247-e1499903322322[1].jpg';
import pleasantHillGarage from '../../assets/CM West Marketing - Part 5 Transit Facilities/bart-pleasant-hill-garage.jpg';

const transitProjects = [
  {
    id: 'sfia-terminal2',
    title: 'SFIA Expansion, Terminal 2 and Boarding Area \'A\' Concourse',
    location: 'San Francisco, CA',
    image: boardingAreaA,
    description: 'Cost estimating, Q/A-Q/C inspection and project management',
    services: ['Cost estimating', 'QA/QC inspection', 'Project management']
  },
  {
    id: 'sfia-security',
    title: 'SFIA Expansion, Security and Special Systems Contract',
    location: 'San Francisco, CA',
    image: sfiaSecuritySystems,
    description: 'Q/A-Q/C inspection and administrative support',
    services: ['QA/QC inspection', 'Administrative support']
  },
  {
    id: 'sfia-utility',
    title: 'SFIA Expansion, 5-Year Underground Utility Study',
    location: 'San Francisco, CA',
    image: sfiaTerminal2,
    description: 'Cost estimating for utility study',
    services: ['Cost estimating']
  },
  {
    id: 'bart-pleasant-hill',
    title: 'BART Pleasant Hill Parking Structure',
    location: 'Pleasant Hill, CA',
    image: pleasantHillGarage,
    description: 'Construction management and project oversight',
    services: ['Construction management', 'Project oversight']
  },
  {
    id: 'central-subway-transit',
    title: 'Central Subway Underground Transit Project',
    location: 'San Francisco, CA',
    image: centralSubway,
    description: 'Program Management/Construction Management Services - tunnel inspection',
    value: '$1.5 Billion',
    services: ['Program management', 'Construction management', 'Tunnel inspection']
  },
  {
    id: 'transbay-terminal',
    title: 'Transbay Terminal',
    location: 'San Francisco, CA',
    image: transbayTerminal,
    description: 'Program management administration and planning',
    value: '$8 Billion',
    services: ['Program management', 'Administration', 'Planning']
  }
];

const TransitFacilities: React.FC = () => {
  return (
    <ProjectCategoryTemplate
      categoryTitle="Transit Facilities"
      categoryDescription="CM West provides construction management services for transit facilities, including airports, train stations, subway systems, and transportation hubs. Our expertise ensures that these complex projects are completed on time and within budget, while meeting all safety and operational requirements."
      projects={transitProjects}
    />
  );
};

export default TransitFacilities; 