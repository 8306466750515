import React from 'react';
import styled, { css } from 'styled-components';

type ButtonVariant = 'primary' | 'secondary';
type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  $fullWidth?: boolean;
  as?: React.ElementType;
  to?: string;
}

const ButtonStyles = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-fast);
  cursor: pointer;
  
  ${props => props.$fullWidth && css`
    width: 100%;
  `}
  
  /* Size variants */
  ${props => props.size === 'small' && css`
    padding: 8px 16px;
    font-size: var(--small-desktop);
  `}
  
  ${props => (props.size === 'medium' || !props.size) && css`
    padding: 12px 24px;
    font-size: var(--body-desktop);
  `}
  
  ${props => props.size === 'large' && css`
    padding: 16px 32px;
    font-size: var(--body-desktop);
  `}
  
  /* Style variants */
  ${props => (props.variant === 'primary' || !props.variant) && css`
    background-color: var(--gold);
    color: var(--white);
    border: none;
    
    &:hover, &:focus {
      background-color: #c09c30; /* Darker gold */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    &:active {
      transform: translateY(1px);
    }
  `}
  
  ${props => props.variant === 'secondary' && css`
    background-color: transparent;
    color: var(--navy-blue);
    border: 2px solid var(--navy-blue);
    
    &:hover, &:focus {
      background-color: var(--navy-blue);
      color: var(--white);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    &:active {
      transform: translateY(1px);
    }
  `}
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    box-shadow: none;
    
    &:hover {
      transform: none;
    }
  }
`;

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'medium',
  fullWidth = false,
  ...props
}) => {
  return (
    <ButtonStyles
      variant={variant}
      size={size}
      $fullWidth={fullWidth}
      {...props}
    >
      {children}
    </ButtonStyles>
  );
};

export default Button; 