import React from 'react';
import ProjectCategoryTemplate from './ProjectCategoryTemplate';

// Import water facility images
import oceansideTreatmentFacility from '../../assets/CM West Marketing - Part 2 Water Projects/CM West - Oceanside Treatment Facility.jpg';
import sunolWaterTreatment from '../../assets/CM West Marketing - Part 2 Water Projects/Sunol Water Treatment Facility MG_0316_1.jpg';
import sfpucSoutheastPlant from '../../assets/CM West Marketing - Part 2 Water Projects/SFPUC Southeast Plant.jpg';

const waterProjects = [
  {
    id: 'sfpuc-primary-sedimentation',
    title: 'SFPUC - Primary Sedimentation Facility Seismic Upgrades - Building 042',
    location: 'San Francisco, CA',
    image: sfpucSoutheastPlant,
    description: 'Cost estimating for SOHA structural engineers',
    services: ['Cost estimating']
  },
  {
    id: 'sfpuc-chloramination',
    title: 'SFPUC - Sunol Valley and Harry Tracy Chloramination Facilities',
    location: 'San Francisco, CA',
    image: sunolWaterTreatment,
    description: 'QA/QC inspection and project management',
    services: ['QA/QC inspection', 'Project management']
  },
  {
    id: 'oceanside-osp3-osp7',
    title: 'Oceanside Plant (OSP) Buildings #3 and #7 Health & Safety Upgrades',
    location: 'San Francisco, CA',
    image: oceansideTreatmentFacility,
    description: 'Campus-wide Mechanical Ductwork (OSP #3) and Administrative Building (OSP #7) - Cost estimating',
    client: 'SFDPW / SFPUC',
    services: ['Cost estimating']
  }
];

const WaterProjects: React.FC = () => {
  return (
    <ProjectCategoryTemplate
      categoryTitle="Water Facilities"
      categoryDescription="CM West specializes in construction management for water infrastructure facilities, including treatment facilities, water distribution systems, and wastewater management. Our expertise ensures that these essential facilities meet all regulatory requirements and operate efficiently."
      projects={waterProjects}
    />
  );
};

export default WaterProjects; 