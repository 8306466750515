import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface ProjectCardProps {
  id: string;
  title: string;
  location: string;
  image: any; // Allow both string URLs and imported images
  category: string;
  className?: string;
}

const CardContainer = styled(Link)`
  display: block;
  position: relative;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  height: 300px;
  text-decoration: none;
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  &:hover .overlay {
    opacity: 1;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-normal);
  
  ${CardContainer}:hover & {
    transform: scale(1.05);
  }
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  padding: var(--spacing-lg);
  color: var(--white);
  opacity: 0.9;
  transition: opacity var(--transition-normal);
  
  ${CardContainer}:hover & {
    opacity: 1;
  }
`;

const Title = styled.h3`
  margin: 0 0 var(--spacing-xs) 0;
  font-size: 20px;
  color: var(--white);
`;

const Location = styled.p`
  margin: 0;
  font-size: 14px;
  color: var(--light-gray);
`;

const Category = styled.span`
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  background-color: var(--gold);
  color: var(--white);
  padding: 4px 8px;
  border-radius: var(--border-radius-sm);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

const ProjectCard: React.FC<ProjectCardProps> = ({
  id,
  title,
  location,
  image,
  category,
  className,
}) => {
  return (
    <CardContainer to={`/projects/${category.toLowerCase().replace(/\s+/g, '-')}`} className={className}>
      <ProjectImage src={image} alt={title} />
      <Category>{category}</Category>
      <Overlay className="overlay">
        <Title>{title}</Title>
        <Location>{location}</Location>
      </Overlay>
    </CardContainer>
  );
};

export default ProjectCard; 