import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import ServicesPage from './pages/ServicesPage';
import ProjectsPage from './pages/ProjectsPage';
import About from './pages/About';
import Contact from './pages/Contact';
import CurrentProjects from './pages/CurrentProjects';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// Import project category pages
import Healthcare from './pages/projects/Healthcare';
import Educational from './pages/projects/Educational';
import WaterProjects from './pages/projects/WaterProjects';
import MunicipalityFacilities from './pages/projects/MunicipalityFacilities';
import TransitFacilities from './pages/projects/TransitFacilities';
import ProjectDetail from './pages/projects/ProjectDetail';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/current-projects" element={<CurrentProjects />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          {/* Project category routes */}
          <Route path="/projects/healthcare" element={<Healthcare />} />
          <Route path="/projects/educational" element={<Educational />} />
          <Route path="/projects/water-projects" element={<WaterProjects />} />
          <Route path="/projects/municipality-facilities" element={<MunicipalityFacilities />} />
          <Route path="/projects/transit-facilities" element={<TransitFacilities />} />

          {/* Project detail route */}
          <Route path="/projects/detail/:projectId" element={<ProjectDetail />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
