import React from 'react';
import ProjectCategoryTemplate from './ProjectCategoryTemplate';

// Import healthcare project images
import davidGrantMedicalCenter from '../../assets/CM West Marketing - Part 1 Healthcare/David Grant Medical Center - Photo.jpg';
import lagunaHondaHospital from '../../assets/CM West Marketing - Part 1 Healthcare/Link Building at Laguna Honda Hospital.jpg';
import paloAltoVeteransHospital from '../../assets/CM West Marketing - Part 1 Healthcare/Palo Alto Veterans Hospital - Photo.jpg';
import soarHivFacility from '../../assets/CM West Marketing - Part 1 Healthcare/SOAR HIV Facility 25 Van Ness 1.jpg';
import highlandHospital from '../../assets/CM West Marketing - Part 1 Healthcare/Highland Hospital 9.jpg';
import kaiserPermanente from '../../assets/CM West Marketing - Part 1 Healthcare/Kaiser Permanente Medical Center - SSF - Photo.jpg';
import sfGeneralHospital from '../../assets/CM West Marketing - Part 1 Healthcare/SF General Hospital - 001.jpg';
import southeastHealthCenter from '../../assets/CM West Marketing - Part 1 Healthcare/Southeast Health Center, SF.jpg';
import sfDepartmentPublicHealth from '../../assets/CM West Marketing - Part 1 Healthcare/San Francisco Department of Public Health.JPG';

const healthcareProjects = [
  {
    id: 'sfgh-building5-80-90',
    title: 'San Francisco General Hospital - Building #5 and Building #80/90 Seismic, Systems and ADA Upgrades',
    location: 'San Francisco, CA',
    image: sfGeneralHospital,
    description: 'Conceptual cost estimating and planning for 2016 Public Health and Safety Bond Program',
    client: 'OSHPD',
    value: '$250 Million',
    services: ['Conceptual cost estimating', 'Planning']
  },
  {
    id: 'highland-hospital',
    title: 'Highland Hospital Acute Tower Replacement',
    location: 'Oakland, CA',
    image: highlandHospital,
    description: 'Project planning, project management, construction management, document control and office engineering',
    client: 'OSHPD',
    value: '$660 Million',
    services: ['Project planning', 'Project management', 'Construction management', 'Document control', 'Office engineering']
  },
  {
    id: 'kaiser-south-sf',
    title: 'Kaiser Permanente - South San Francisco (MOB and Head & Neck Surgery)',
    location: 'South San Francisco, CA',
    image: kaiserPermanente,
    description: 'Cost estimating and contractor estimate reviews',
    client: 'OSHPD',
    services: ['Cost estimating', 'Contractor estimate reviews']
  },
  {
    id: 'laguna-honda-replacement',
    title: 'Laguna Honda Hospital Replacement Program',
    location: 'San Francisco, CA',
    image: lagunaHondaHospital,
    description: 'Cost estimating, change proposal reviews, constructability reviews, document controls, office engineering and field engineering',
    client: 'OSHPD',
    value: '$350 Million',
    services: ['Cost estimating', 'Change proposal reviews', 'Constructability reviews', 'Document controls', 'Office engineering', 'Field engineering']
  },
  {
    id: 'sfdph-health-clinic',
    title: 'San Francisco Department of Public Health - Neighborhood Health Clinic Renovations',
    location: 'San Francisco, CA',
    image: sfDepartmentPublicHealth,
    description: 'Upgrades For ADA/Seismic - Cost estimating on 10 health clinic facilities',
    services: ['Cost estimating']
  },
  {
    id: 'davis-grant-medical',
    title: 'Davis S. Grant Medical Center, Travis Air Force Base (Phase 1)',
    location: 'Fairfield, CA',
    image: davidGrantMedicalCenter,
    description: '$25 Million electrical contract completion and evaluation for surety on contract default - cost estimating the final cost-to-complete',
    value: '$25 Million',
    services: ['Contract completion evaluation', 'Cost estimating']
  },
  {
    id: 'veterans-hospital',
    title: 'Veterans Hospital',
    location: 'Palo Alto, CA',
    image: paloAltoVeteransHospital,
    description: 'Tile contract completion and evaluation for surety on contract default',
    services: ['Contract completion evaluation']
  },
  {
    id: 'soar-hiv',
    title: 'SOAR HIV Research Facility Renovation',
    location: '25 Van Ness Avenue, San Francisco, CA',
    image: soarHivFacility,
    description: 'Cost estimating for research facility renovation',
    services: ['Cost estimating']
  },
  {
    id: 'sfdph-southeast',
    title: 'SFDPH Southeast Health Center',
    location: 'San Francisco, CA',
    image: southeastHealthCenter,
    description: 'Constructability Reviews and Scheduling',
    services: ['Constructability reviews', 'Scheduling']
  }
];

const Healthcare: React.FC = () => {
  return (
    <ProjectCategoryTemplate
      categoryTitle="Healthcare"
      categoryDescription="CM West has extensive experience in healthcare facility construction management, including hospitals, medical centers, and specialized healthcare facilities. Our expertise ensures that these critical facilities are built to the highest standards of quality and safety."
      projects={healthcareProjects}
    />
  );
};

export default Healthcare; 