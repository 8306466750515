import React from 'react';
import ProjectCategoryTemplate from './ProjectCategoryTemplate';

// Import municipality facility images with correct naming
import bayviewOperaHouse from '../../assets/CM West Marketing - Part 4 Municipality Facilities/BayView Opera House, SF.png';
import bayviewLibrary from '../../assets/CM West Marketing - Part 4 Municipality Facilities/CM West - Bayview Library.jpg';
import missionCulturalCenter from '../../assets/CM West Marketing - Part 4 Municipality Facilities/Mission Cultural Center For Latino Arts, SF.png';
import sffdStation13 from '../../assets/CM West Marketing - Part 4 Municipality Facilities/SFFD Station 13_z.jpg';
import sffdStation15 from '../../assets/CM West Marketing - Part 4 Municipality Facilities/SFFD Station 15_z.jpg';
import sffdStation35AndFireBoat from '../../assets/CM West Marketing - Part 4 Municipality Facilities/SFFD Station 35 & Fire Boat 1.jpg';
import sffdStation1 from '../../assets/CM West Marketing - Part 4 Municipality Facilities/SFFD Station 1.jpg';
import parkside from '../../assets/CM West Marketing - Part 4 Municipality Facilities/Parkside 2 (1).jpg';
import sunsetLibrary from '../../assets/CM West Marketing - Part 4 Municipality Facilities/Sunset Branch Library - 001.jpg';
import visitacionValleyLibrary from '../../assets/CM West Marketing - Part 4 Municipality Facilities/Visitacion Valley Branch Library 1.jpg';
import hallOfJustice from '../../assets/CM West Marketing - Part 4 Municipality Facilities/hall_of_justice.png';

// Note: Moscone Convention Center 109.JPG and Marin Hall of Justice PDF are not imported due to file type issues

const municipalityProjects = [
  {
    id: 'sffd-facilities',
    title: 'SFDPW / City and County of San Francisco Fire Department Facilities',
    location: 'San Francisco, CA',
    image: sffdStation15,
    description: 'Seismic and ADA and Repair Upgrades - Cost estimating on 40 facilities',
    services: ['Cost estimating']
  },
  {
    id: 'sf-branch-library',
    title: 'San Francisco Branch Library Improvement Program',
    location: 'San Francisco, CA',
    image: visitacionValleyLibrary,
    description: 'Cost estimating on 15 branch library facilities',
    services: ['Cost estimating']
  },
  {
    id: 'sf-hall-justice',
    title: 'SF Hall of Justice Planning',
    location: '850 Bryant Street, San Francisco, CA',
    image: hallOfJustice,
    description: 'Conceptual cost estimating for justice facility',
    services: ['Conceptual cost estimating']
  },
  {
    id: 'bayview-opera-house',
    title: 'Bayview Opera House',
    location: 'San Francisco, CA',
    image: bayviewOperaHouse,
    description: 'Historic renovation and seismic upgrades',
    services: ['Historic preservation', 'Seismic upgrades']
  },
  {
    id: 'mission-cultural-center',
    title: 'Mission Cultural Center For Latino Arts',
    location: 'San Francisco, CA',
    image: missionCulturalCenter,
    description: 'Facility improvements and renovations',
    services: ['Facility improvements', 'Renovation']
  },
  {
    id: 'sffd-station-13',
    title: 'SFFD Station 13',
    location: 'San Francisco, CA',
    image: sffdStation13,
    description: 'Fire station seismic and ADA upgrades',
    services: ['Seismic upgrades', 'ADA compliance']
  },
  {
    id: 'sffd-station-35',
    title: 'SFFD Station 35 & Fire Boat 1',
    location: 'San Francisco, CA',
    image: sffdStation35AndFireBoat,
    description: 'Fire station and marine unit facility upgrades',
    services: ['Facility upgrades']
  },
  {
    id: 'sffd-station-1',
    title: 'SFFD Station 1',
    location: 'San Francisco, CA',
    image: sffdStation1,
    description: 'Fire station modernization and upgrades',
    services: ['Facility modernization']
  },
  {
    id: 'parkside-library',
    title: 'Parkside Branch Library',
    location: 'San Francisco, CA',
    image: parkside,
    description: 'Library renovation and improvements',
    services: ['Renovation', 'Facility improvements']
  },
  {
    id: 'sunset-library',
    title: 'Sunset Branch Library',
    location: 'San Francisco, CA',
    image: sunsetLibrary,
    description: 'Library renovation and modernization',
    services: ['Renovation', 'Modernization']
  },
  {
    id: 'bayview-library',
    title: 'Bayview Library',
    location: 'San Francisco, CA',
    image: bayviewLibrary,
    description: 'Library renovation and modernization as part of the Branch Library Improvement Program',
    services: ['Renovation', 'Modernization']
  }
];

const MunicipalityFacilities: React.FC = () => {
  return (
    <ProjectCategoryTemplate
      categoryTitle="Municipality Facilities"
      categoryDescription="CM West has extensive experience in construction management for municipal facilities, including libraries, fire stations, community centers, and government buildings. We work closely with city agencies to deliver projects that serve the public and enhance community infrastructure."
      projects={municipalityProjects}
    />
  );
};

export default MunicipalityFacilities; 