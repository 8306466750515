import React from 'react';
import styled from 'styled-components';

interface SectionTitleProps {
  title: string;
  subtitle?: string;
  centered?: boolean;
  className?: string;
}

interface StyledProps {
  $centered?: boolean;
}

const TitleContainer = styled.div<StyledProps>`
  margin-bottom: var(--spacing-xl);
  text-align: ${props => props.$centered ? 'center' : 'left'};
`;

const Title = styled.h2`
  color: var(--navy-blue);
  position: relative;
  display: inline-block;
  margin-bottom: ${props => props.children ? 'var(--spacing-md)' : '0'};
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--gold);
  }
`;

const CenteredTitle = styled(Title)`
  &::after {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

const Subtitle = styled.p<StyledProps>`
  color: var(--dark-gray);
  font-size: 18px;
  max-width: 600px;
  margin: ${props => props.$centered ? '0 auto' : '0'};
`;

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitle,
  centered = false,
  className,
}) => {
  const TitleComponent = centered ? CenteredTitle : Title;

  return (
    <TitleContainer $centered={centered} className={className}>
      <TitleComponent>{title}</TitleComponent>
      {subtitle && <Subtitle $centered={centered}>{subtitle}</Subtitle>}
    </TitleContainer>
  );
};

export default SectionTitle; 