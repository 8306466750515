import React from 'react';
import styled from 'styled-components';
import Hero from '../components/Hero/Hero';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import ServiceCard from '../components/ServiceCard/ServiceCard';
import ProjectCard from '../components/ProjectCard/ProjectCard';
import Button from '../components/Button/Button';
import { Link } from 'react-router-dom';

// Import featured project images from different categories
import sfGeneralHospital from '../assets/CM West Marketing - Part 1 Healthcare/SF General Hospital - 001.jpg';
import cityCollegeChinatown from '../assets/CM West Marketing - Part 3 Educational/City College - Chinatown  North Beach Campus 1.jpg';
import sunolWaterTreatment from '../assets/CM West Marketing - Part 2 Water Projects/Sunol Water Treatment Facility MG_0316_1.jpg';
import sfiaTerminal2 from '../assets/CM West Marketing - Part 5 Transit Facilities/CM West SFIA Terminal 2.jpg';
import bayviewOperaHouse from '../assets/CM West Marketing - Part 4 Municipality Facilities/BayView Opera House, SF.png';

// Hero slides with placeholder images
const heroSlides = [
  {
    image: 'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
    title: 'Building Excellence in Construction Management',
    subtitle: 'Delivering quality projects on time and within budget',
  },
  {
    image: 'https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1931&q=80',
    title: 'Expert Construction Management Services',
    subtitle: 'From concept to completion, we manage every detail',
  },
  {
    image: 'https://images.unsplash.com/photo-1531834685032-c34bf0d84c77?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80',
    title: 'Innovative Solutions for Complex Projects',
    subtitle: 'Bringing expertise to healthcare, educational, and infrastructure projects',
  },
];

// Temporary service data - will be replaced with actual service descriptions
const services = [
  {
    id: 'program-management',
    title: 'Program & Project Management',
    description: 'Comprehensive management services to oversee complex construction projects from inception to completion, ensuring they are delivered on time and within budget.',
    icon: '🏗️',
  },
  {
    id: 'pre-construction',
    title: 'Pre-Construction Services',
    description: 'Expert pre-construction services including program planning, scheduling, cost estimating, constructability reviews, and value engineering to set your project up for success.',
    icon: '📋',
  },
  {
    id: 'cost-estimating',
    title: 'Cost Estimating & Control',
    description: 'Detailed cost estimating and control services to help you understand project costs and maintain budgets throughout the construction process.',
    icon: '💰',
  },
  {
    id: 'quality-control',
    title: 'Quality Control & Inspections',
    description: 'Comprehensive quality control inspections to ensure all work meets specifications and standards, delivering a high-quality finished product.',
    icon: '✓',
  },
];

// Featured projects with actual project images from different categories
// Clicking on a project will navigate to its category page (e.g., /projects/healthcare)
const featuredProjects = [
  {
    id: 'sf-general-hospital',
    title: 'San Francisco General Hospital',
    location: 'San Francisco, CA',
    image: sfGeneralHospital,
    category: 'Healthcare',
  },
  {
    id: 'city-college-chinatown',
    title: 'City College - Chinatown/North Beach Campus',
    location: 'San Francisco, CA',
    image: cityCollegeChinatown,
    category: 'Educational',
  },
  {
    id: 'sunol-water-treatment',
    title: 'Sunol Water Treatment Facility',
    location: 'Sunol, CA',
    image: sunolWaterTreatment,
    category: 'Water Projects',
  },
  {
    id: 'sfia-terminal-2',
    title: 'SFIA Terminal 2',
    location: 'San Francisco International Airport, CA',
    image: sfiaTerminal2,
    category: 'Transit Facilities',
  },
  {
    id: 'bayview-opera-house',
    title: 'Bayview Opera House',
    location: 'San Francisco, CA',
    image: bayviewOperaHouse,
    category: 'Municipality Facilities',
  },
];

const Section = styled.section`
  padding: var(--spacing-xxl) 0;
`;

const Container = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
`;

const AboutSection = styled(Section)`
  background-color: var(--light-gray);
`;

const ValueSection = styled(Section)`
  background-color: var(--white);
`;

const ValueContent = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-lg);
  margin-top: var(--spacing-xl);
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ValueItem = styled.div`
  background-color: var(--light-gray);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  h3 {
    color: var(--navy-blue);
    margin-bottom: var(--spacing-sm);
    display: flex;
    align-items: center;
  }
  
  .number {
    background-color: var(--navy-blue);
    color: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--spacing-sm);
    font-weight: bold;
  }
`;

const AboutContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  align-items: center;
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const AboutText = styled.div`
  p {
    margin-bottom: var(--spacing-md);
  }
`;

const AboutImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 767px) {
    order: -1;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-lg);
  
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-xl);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Home: React.FC = () => {
  return (
    <>
      <Hero slides={heroSlides} />

      <AboutSection>
        <Container>
          <AboutContent>
            <AboutText>
              <SectionTitle
                title="About CM West"
                subtitle="Construction Management West, Inc. is a leading construction management firm serving the San Francisco Bay Area and beyond since 1987."
              />
              <p>
                Founded by Lionel J. Recio, AIA, who is both a Registered Architect and Licensed General Contractor, CM West has developed a reputation for delivering uniquely personalized service over the past 35+ years. Our company prides itself on being flexible and utilitarian – able to adapt quickly to evolving project requirements.
              </p>
              <p>
                While our expertise spans building-type facilities including airports, hospitals, libraries, educational, correctional and convention facilities, we have also successfully managed civil and infrastructure projects including underground utilities, highways, pump stations, reservoirs, and industrial facilities.
              </p>
              <p>
                As a small, minority-owned local business certified with agencies including the Contracts Monitoring Division of San Francisco (CMD), Alameda County (SLEB), and Caltrans (State of California – SMBE), we maintain offices in San Francisco and Oakland.
              </p>
              <StyledLink to="/about#top">
                <Button variant="secondary">Learn More About Us</Button>
              </StyledLink>
            </AboutText>
            <AboutImage
              src="https://images.unsplash.com/photo-1504307651254-35680f356dfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80"
              alt="Construction Management Team"
            />
          </AboutContent>
        </Container>
      </AboutSection>

      <ValueSection>
        <Container>
          <SectionTitle
            title="Client Value"
            subtitle="Ten excellent reasons why you should consider engaging the services of Construction Management West, Inc."
            centered
          />
          <ValueContent>
            <ValueGrid>
              <ValueItem>
                <h3><span className="number">1</span> Save Time & Money</h3>
                <p>An interest to save both time and money on the project.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">2</span> Limit Liability</h3>
                <p>An interest in limiting your exposure to project liability.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">3</span> Best Value</h3>
                <p>An interest in obtaining the "best value" possible on the project design and construction.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">4</span> Optimum Delivery</h3>
                <p>An interest in having the "optimum" project delivery method best suited for your project.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">5</span> Budget & Schedule Control</h3>
                <p>An interest in managing your project budget and schedule closely and accurately to avoid unnecessary cost overruns and delays.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">6</span> Fair Pricing</h3>
                <p>An interest in paying a reasonable and fair price for the appropriate services best suited for your project throughout every phase.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">7</span> Experienced Representation</h3>
                <p>An interest in having an experienced and objective representative continually operating in your best interest at work with the design team and building contractors.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">8</span> Dedicated Team</h3>
                <p>An interest in having more than a random consultant on your team - an interest in having a dedicated team professional committed to understanding your objectives and effectively implementing them throughout the entire project.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">9</span> State-of-the-Art Controls</h3>
                <p>An interest in knowing that your project is being monitored with proven state-of-the-art project controls and methods.</p>
              </ValueItem>
              <ValueItem>
                <h3><span className="number">10</span> Satisfaction</h3>
                <p>An interest in enjoying the satisfaction at the end of your project that you had engaged a trustworthy representative of your interests.</p>
              </ValueItem>
            </ValueGrid>
          </ValueContent>
        </Container>
      </ValueSection>

      <Section>
        <Container>
          <SectionTitle
            title="Our Services"
            subtitle="We offer a comprehensive range of construction management services throughout all phases of your project."
            centered
          />

          <ServicesGrid>
            {services.map(service => (
              <ServiceCard
                key={service.id}
                id={service.id}
                title={service.title}
                description={service.description}
                icon={service.icon}
                showLearnMore={false}
              />
            ))}
          </ServicesGrid>

          <ButtonContainer>
            <StyledLink to="/services#top">
              <Button>View All Services</Button>
            </StyledLink>
          </ButtonContainer>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle
            title="Featured Projects"
            subtitle="Explore some of our recent projects across various sectors."
            centered
          />

          <ProjectsGrid>
            {featuredProjects.map(project => (
              <ProjectCard
                key={project.id}
                id={project.id}
                title={project.title}
                location={project.location}
                image={project.image}
                category={project.category}
              />
            ))}
          </ProjectsGrid>

          <ButtonContainer>
            <StyledLink to="/projects#top">
              <Button>View All Projects</Button>
            </StyledLink>
          </ButtonContainer>
        </Container>
      </Section>
    </>
  );
};

export default Home; 