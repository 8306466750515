import React, { useState } from 'react';
import styled from 'styled-components';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import Button from '../components/Button/Button';

const PageContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-xxl) var(--spacing-lg);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-xxl);
`;

const PageTitle = styled.h1`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-md);
`;

const PageDescription = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ContactInfo = styled.div`
  p {
    margin-bottom: var(--spacing-md);
  }
`;

const InfoItem = styled.div`
  margin-bottom: var(--spacing-lg);
`;

const InfoTitle = styled.h3`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-sm);
`;

const InfoContent = styled.p`
  margin: 0;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: var(--spacing-md);
`;

const Label = styled.label`
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 600;
  color: var(--navy-blue);
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  
  &:focus {
    outline: none;
    border-color: var(--navy-blue);
    box-shadow: 0 0 0 2px rgba(26, 42, 74, 0.2);
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  min-height: 150px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: var(--navy-blue);
    box-shadow: 0 0 0 2px rgba(26, 42, 74, 0.2);
  }
`;

const SuccessMessage = styled.div`
  background-color: #d4edda;
  color: #155724;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-lg);
`;

const ErrorMessage = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-lg);
`;

const MapContainer = styled.div`
  margin-top: var(--spacing-xxl);
  height: 400px;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ReferenceSection = styled.div`
  margin-top: var(--spacing-xxl);
  padding-top: var(--spacing-xl);
  border-top: 1px solid #eee;
`;

const ReferenceItem = styled.div`
  margin-bottom: var(--spacing-lg);
`;

const OfficesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xl);
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const OfficeLocation = styled.div`
  margin-bottom: var(--spacing-lg);
`;

const Contact: React.FC = () => {
  const [formStatus, setFormStatus] = useState<'idle' | 'success' | 'error'>('idle');

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Contact Us</PageTitle>
        <PageDescription>
          We'd love to hear from you. Get in touch with our team for inquiries, project consultations, or general information.
        </PageDescription>
      </PageHeader>

      <ContactGrid>
        <ContactInfo>
          <SectionTitle title="Get In Touch" />

          <InfoItem>
            <InfoTitle>Inquiries for our Services</InfoTitle>
            <InfoContent>Call Us: (415) 397-6102</InfoContent>
            <InfoContent>Email us: cmwest@flash.net</InfoContent>
          </InfoItem>

          <OfficesContainer>
            <OfficeLocation>
              <InfoTitle>San Francisco Office</InfoTitle>
              <InfoContent>930 Montgomery St # 200</InfoContent>
              <InfoContent>San Francisco, CA 94133</InfoContent>
            </OfficeLocation>

            <OfficeLocation>
              <InfoTitle>Oakland Office</InfoTitle>
              <InfoContent>101 Broadway 3rd Floor MS242</InfoContent>
              <InfoContent>Oakland, CA 94067</InfoContent>
            </OfficeLocation>
          </OfficesContainer>
        </ContactInfo>

        <div>
          <SectionTitle title="Send Us a Message" />

          {formStatus === 'success' && (
            <SuccessMessage>
              Thank you for your message! We'll get back to you as soon as possible.
            </SuccessMessage>
          )}

          {formStatus === 'error' && (
            <ErrorMessage>
              There was an error sending your message. Please try again later.
            </ErrorMessage>
          )}

          <ContactForm
            action="https://formsubmit.co/cmwest@flash.net"
            method="POST"
            onSubmit={() => setFormStatus('success')}
          >
            {/* FormSubmit configuration */}
            <input type="hidden" name="_subject" value="New contact form submission from CMWest website" />
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_next" value={window.location.href} />
            <input type="text" name="_honey" style={{ display: 'none' }} />

            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="phone">Phone</Label>
              <Input
                type="tel"
                id="phone"
                name="phone"
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="subject">Subject</Label>
              <Input
                type="text"
                id="subject"
                name="subject"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="message">Message</Label>
              <Textarea
                id="message"
                name="message"
                required
              />
            </FormGroup>

            <Button type="submit">Send Message</Button>
          </ContactForm>
        </div>
      </ContactGrid>

      <MapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.5380567355366!2d-122.40520908468173!3d37.79609977975504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580f1a94e0e8d%3A0x7f621d6f3189d027!2s930%20Montgomery%20St%20%23200%2C%20San%20Francisco%2C%20CA%2094133!5e0!3m2!1sen!2sus!4v1652887654321!5m2!1sen!2sus"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="CM West San Francisco Office Location"
        />
      </MapContainer>
    </PageContainer>
  );
};

export default Contact; 