import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SectionTitle from '../components/SectionTitle/SectionTitle';

// Import category images
import healthcareImage from '../assets/CM West Marketing - Part 1 Healthcare/SF General Hospital - 001.jpg';
import waterProjectsImage from '../assets/CM West Marketing - Part 2 Water Projects/Sunol Water Treatment Facility MG_0316_1.jpg';
import educationalImage from '../assets/CM West Marketing - Part 3 Educational/City College - Chinatown  North Beach Campus 1.jpg';
import municipalityImage from '../assets/CM West Marketing - Part 4 Municipality Facilities/CM West - Bayview Library.jpg';
import transitImage from '../assets/CM West Marketing - Part 5 Transit Facilities/CM West SFIA Terminal 2.jpg';

const PageContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-xxl) var(--spacing-lg);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-xxl);
`;

const PageTitle = styled.h1`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-md);
`;

const PageDescription = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
`;

const HistorySection = styled.div`
  text-align: center;
  margin-top: var(--spacing-xxl);
  padding: var(--spacing-xl);
  background-color: var(--light-gray);
  border-radius: var(--border-radius-sm);
`;

const HistoryButton = styled(Link)`
  display: inline-block;
  background-color: var(--navy-blue);
  color: var(--white);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--border-radius-sm);
  text-decoration: none;
  font-weight: 600;
  margin-top: var(--spacing-md);
  transition: background-color var(--transition-normal);
  
  &:hover {
    background-color: var(--dark-blue);
  }
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xxl);
  
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const CategoryCard = styled(Link)`
  position: relative;
  height: 300px;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  display: block;
  
  &:hover img {
    transform: scale(1.05);
  }
  
  &:hover .overlay {
    background-color: rgba(26, 42, 74, 0.7);
  }
`;

const CategoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-normal);
`;

const CategoryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 42, 74, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color var(--transition-normal);
`;

const CategoryTitle = styled.h3`
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 0 var(--spacing-md);
`;

// Project categories data with actual images
const projectCategories = [
  {
    id: 'healthcare',
    title: 'Healthcare',
    image: healthcareImage,
  },
  {
    id: 'educational',
    title: 'Educational',
    image: educationalImage,
  },
  {
    id: 'water-projects',
    title: 'Water Facilities',
    image: waterProjectsImage,
  },
  {
    id: 'municipality-facilities',
    title: 'Municipality Facilities',
    image: municipalityImage,
  },
  {
    id: 'transit-facilities',
    title: 'Transit Facilities',
    image: transitImage,
  },
];

const ProjectsPage: React.FC = () => {
  return (
    <PageContainer>
      <div id="top"></div>
      <PageHeader>
        <PageTitle>Our Projects</PageTitle>
        <PageDescription>
          Explore our diverse portfolio of projects across various sectors, showcasing our expertise and commitment to excellence in construction management.
        </PageDescription>
      </PageHeader>

      <SectionTitle
        title="Project Categories"
        subtitle="Browse our projects by category to see our work in specific sectors."
        centered
      />

      <CategoryGrid>
        {projectCategories.map(category => (
          <CategoryCard key={category.id} to={`/projects/${category.id}`}>
            <CategoryImage src={category.image} alt={category.title} />
            <CategoryOverlay className="overlay">
              <CategoryTitle>{category.title}</CategoryTitle>
            </CategoryOverlay>
          </CategoryCard>
        ))}
      </CategoryGrid>

      <SectionTitle
        title="Featured Projects"
        subtitle="Highlighted projects that showcase our capabilities and expertise."
        centered
      />

      <p style={{ textAlign: 'center' }}>
        [Featured projects will be displayed here]
      </p>

      <HistorySection>
        <SectionTitle
          title="View All Current Projects"
          subtitle="Explore our comprehensive portfolio of ongoing and recently completed projects across all sectors."
          centered
        />
        <p>
          Our diverse range of projects spans healthcare, educational, water infrastructure, municipality facilities, transit, and more. Each project showcases our commitment to excellence in construction management.
        </p>
        <HistoryButton to="/current-projects">
          View All Current Projects
        </HistoryButton>
      </HistorySection>
    </PageContainer>
  );
};

export default ProjectsPage; 