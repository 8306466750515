import React from 'react';
import styled from 'styled-components';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  elevation?: 'low' | 'medium' | 'high';
  hoverEffect?: boolean;
  $hoverEffect?: boolean;
}

const CardContainer = styled.div<Omit<CardProps, 'children'>>`
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-lg);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  ${({ elevation }) => {
    switch (elevation) {
      case 'low':
        return `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);`;
      case 'high':
        return `box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);`;
      case 'medium':
      default:
        return `box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);`;
    }
  }}
  
  ${({ $hoverEffect }) => $hoverEffect && `
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
    }
  `}
`;

const Card: React.FC<CardProps> = ({
  children,
  className,
  elevation = 'medium',
  hoverEffect = false,
}) => {
  return (
    <CardContainer
      className={className}
      elevation={elevation}
      $hoverEffect={hoverEffect}
    >
      {children}
    </CardContainer>
  );
};

export default Card; 