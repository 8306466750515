import React from 'react';
import styled from 'styled-components';
import SectionTitle from '../components/SectionTitle/SectionTitle';

const PageContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-xxl) var(--spacing-lg);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-xxl);
`;

const PageTitle = styled.h1`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-md);
`;

const PageDescription = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
`;

const ServiceSection = styled.section`
  margin-bottom: var(--spacing-xxl);
  scroll-margin-top: 100px; /* For smooth scrolling to anchors */
`;

const ServiceContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  align-items: center;
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceDescription = styled.div`
  p {
    margin-bottom: var(--spacing-md);
  }
  
  ul {
    margin-bottom: var(--spacing-md);
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 767px) {
    order: -1;
    margin-bottom: var(--spacing-lg);
  }
`;

const ServicePhaseSection = styled.section`
  margin-bottom: var(--spacing-xxl);
`;

const PhaseTitle = styled.h2`
  color: var(--navy-blue);
  margin-bottom: var(--spacing-lg);
  text-align: center;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background-color: var(--light-gray);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  h3 {
    color: var(--navy-blue);
    margin-bottom: var(--spacing-sm);
  }
`;

const ServicesPage: React.FC = () => {
  return (
    <PageContainer>
      <div id="top"></div>
      <PageHeader>
        <PageTitle>Our Services</PageTitle>
        <PageDescription>
          Construction Management West, Inc. offers a comprehensive range of construction management services tailored to the specific needs of our clients. Our seasoned construction personnel are highly sensitive to project cost and time issues, enabling our clients to receive maximum benefit from state-of-the-art project control methods.
        </PageDescription>
      </PageHeader>

      <p style={{ textAlign: 'center', marginBottom: 'var(--spacing-xxl)' }}>
        The services that we offer provide for an array of professional disciplines from the early initial concepts of a project through the completion and commissioning. Since inception, the firm has remained a very personalized full-service construction management organization providing services for clientele such as municipalities, public agencies, real estate developers, surety companies, attorneys, design professionals, construction contractors and consultants.
      </p>

      <ServicePhaseSection>
        <PhaseTitle>Planning / Design</PhaseTitle>
        <ServiceGrid>
          <ServiceCard id="pre-construction">
            <h3>Project Planning</h3>
            <p>Comprehensive planning services to establish a solid foundation for your project's success.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Strategic Planning</h3>
            <p>Long-term planning to align your construction projects with your organizational goals.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Feasibility Studies</h3>
            <p>Detailed analysis to determine the viability and potential challenges of your project.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Facility Systems Evaluations</h3>
            <p>Assessment of building systems to identify improvement opportunities and potential issues.</p>
          </ServiceCard>
          <ServiceCard id="cost-estimating">
            <h3>Budget & Cost Estimating</h3>
            <p>Accurate cost projections to help you plan and manage your project budget effectively.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Document Peer Reviews</h3>
            <p>Independent review of project documents to ensure quality, completeness, and accuracy.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Constructability Reviews</h3>
            <p>Analysis of design documents to identify potential construction issues before they occur.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Value Engineering</h3>
            <p>Systematic approach to optimize project value while maintaining quality and performance.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>MIS / Document Control</h3>
            <p>Efficient management of project information and documentation throughout the project lifecycle.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Permit Expediting</h3>
            <p>Streamlined permitting process to avoid delays and ensure regulatory compliance.</p>
          </ServiceCard>
        </ServiceGrid>
      </ServicePhaseSection>

      <ServicePhaseSection>
        <PhaseTitle>Bid Process / Contractor Selection</PhaseTitle>
        <ServiceGrid>
          <ServiceCard>
            <h3>Project Delivery Planning</h3>
            <p>Strategic planning to determine the most effective project delivery method for your specific needs.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Bid Packaging</h3>
            <p>Preparation of comprehensive bid packages to attract qualified contractors and ensure competitive pricing.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Logistics Planning</h3>
            <p>Detailed planning for material delivery, site access, and construction sequencing to optimize efficiency.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>General Conditions Evaluation</h3>
            <p>Review and development of appropriate general conditions to protect your interests during construction.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Contractor Pre-Qualification</h3>
            <p>Rigorous screening process to ensure only qualified contractors are considered for your project.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Negotiated Contracts</h3>
            <p>Expert negotiation of contract terms to achieve favorable conditions and pricing for your project.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Bid Evaluations</h3>
            <p>Thorough analysis of submitted bids to identify the best value proposition for your project.</p>
          </ServiceCard>
        </ServiceGrid>
      </ServicePhaseSection>

      <ServicePhaseSection>
        <PhaseTitle>Construction / Close-out</PhaseTitle>
        <ServiceGrid>
          <ServiceCard id="program-management">
            <h3>Project Scheduling</h3>
            <p>Development and management of detailed project schedules to ensure timely completion.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Change Proposal Review</h3>
            <p>Evaluation of change orders to verify scope, pricing, and impact on project schedule.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Document Controls</h3>
            <p>Management of project documentation to maintain accurate records throughout construction.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Project Controls</h3>
            <p>Implementation of systems to monitor and control project cost, schedule, and quality.</p>
          </ServiceCard>
          <ServiceCard id="quality-control">
            <h3>QC/QA Inspection</h3>
            <p>Rigorous quality control and assurance inspections to ensure work meets specifications.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Management Reporting</h3>
            <p>Regular, comprehensive reporting on project status, issues, and performance metrics.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Contract Administration</h3>
            <p>Management of contractual obligations to protect your interests throughout construction.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Cost Monitoring</h3>
            <p>Continuous tracking of project costs to identify and address potential budget issues.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Claims Management & Mitigation</h3>
            <p>Proactive management of potential claims to minimize disputes and project impacts.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Project Close-out</h3>
            <p>Comprehensive close-out process to ensure all contractual obligations are fulfilled.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Commissioning</h3>
            <p>Systematic verification that all building systems function as designed and meet project requirements.</p>
          </ServiceCard>
        </ServiceGrid>
      </ServicePhaseSection>

      <ServicePhaseSection>
        <PhaseTitle>Related Services</PhaseTitle>
        <ServiceGrid>
          <ServiceCard>
            <h3>Litigation Support & Assistance</h3>
            <p>Expert support for construction-related legal proceedings and dispute resolution.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Mediation & Dispute Settlement Resolution</h3>
            <p>Facilitation of dispute resolution to avoid costly litigation and project delays.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Design Defect Analysis</h3>
            <p>Identification and assessment of design defects and their impact on project performance.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Contract Completion Cost Estimates</h3>
            <p>Accurate estimation of costs to complete projects in challenging situations.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Material Defect Claim Analysis</h3>
            <p>Investigation and evaluation of claims related to defective materials.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Material Warranty Claims Analysis</h3>
            <p>Assessment of warranty claims to ensure proper coverage and resolution.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Schedule / Delay Claim Analysis</h3>
            <p>Forensic analysis of project delays to determine causes and responsibilities.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Expert Witness Testimony</h3>
            <p>Qualified expert testimony for construction-related legal proceedings.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Forensic Design & Construction Review</h3>
            <p>Detailed investigation of design and construction issues to identify root causes.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Contractor Default / Termination Analysis</h3>
            <p>Evaluation of contractor performance and options in default situations.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Performance & Payment Bond Analysis</h3>
            <p>Assessment of bond claims and coverage for construction projects.</p>
          </ServiceCard>
          <ServiceCard>
            <h3>Surety Construction Consulting</h3>
            <p>Specialized consulting services for surety companies involved in construction projects.</p>
          </ServiceCard>
        </ServiceGrid>
      </ServicePhaseSection>

      <h2 style={{ textAlign: 'center', marginBottom: 'var(--spacing-xl)', marginTop: 'var(--spacing-xxl)' }}>Featured Services</h2>

      <ServiceSection id="program-management">
        <SectionTitle title="Program & Project Management" />
        <ServiceContent>
          <ServiceDescription>
            <p>
              Our Program and Project Management services provide comprehensive oversight and coordination for complex construction projects from inception to completion. We serve as your trusted partner, ensuring that all aspects of your project are managed efficiently and effectively.
            </p>
            <p>
              Our approach to Program and Project Management includes:
            </p>
            <ul>
              <li>Project planning and scheduling</li>
              <li>Budget development and cost control</li>
              <li>Risk assessment and mitigation</li>
              <li>Stakeholder coordination and communication</li>
              <li>Quality assurance and control</li>
              <li>Contract administration</li>
              <li>Performance monitoring and reporting</li>
            </ul>
            <p>
              With our Program and Project Management services, you can be confident that your project will be delivered on time, within budget, and to the highest standards of quality.
            </p>
          </ServiceDescription>
          <ServiceImage
            src="https://images.unsplash.com/photo-1504917595217-d4dc5ebe6122?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80"
            alt="Program Management"
          />
        </ServiceContent>
      </ServiceSection>

      <ServiceSection id="pre-construction">
        <SectionTitle title="Pre-Construction Services" />
        <ServiceContent>
          <ServiceImage
            src="https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
            alt="Pre-Construction Services"
          />
          <ServiceDescription>
            <p>
              Our Pre-Construction Services help set your project up for success from the very beginning. We work closely with you during the critical early phases to establish a solid foundation for your project.
            </p>
            <p>
              Our Pre-Construction Services include:
            </p>
            <ul>
              <li>Program management and planning</li>
              <li>Contractor procurement and bid processing</li>
              <li>Constructability reviews</li>
              <li>Value engineering</li>
              <li>Scheduling and phasing strategies</li>
              <li>Budget development and cost analysis</li>
              <li>Permitting assistance</li>
            </ul>
            <p>
              By engaging our Pre-Construction Services, you can identify and address potential challenges early, optimize your project approach, and establish realistic expectations for cost, schedule, and quality.
            </p>
          </ServiceDescription>
        </ServiceContent>
      </ServiceSection>

      <ServiceSection id="claims-dispute">
        <SectionTitle title="Claims Analysis & Dispute Resolution" />
        <ServiceContent>
          <ServiceDescription>
            <p>
              Our Claims Analysis and Dispute Resolution services help navigate the complexities of construction disputes and claims. We provide objective analysis and expert guidance to resolve issues efficiently and fairly.
            </p>
            <p>
              Our Claims Analysis and Dispute Resolution services include:
            </p>
            <ul>
              <li>Claim preparation and evaluation</li>
              <li>Schedule delay analysis</li>
              <li>Cost impact assessment</li>
              <li>Contract interpretation</li>
              <li>Documentation review and analysis</li>
              <li>Expert testimony</li>
              <li>Mediation and negotiation support</li>
            </ul>
            <p>
              Our goal is to help resolve disputes quickly and cost-effectively, minimizing the impact on project progress and relationships.
            </p>
          </ServiceDescription>
          <ServiceImage
            src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80"
            alt="Claims Analysis & Dispute Resolution"
          />
        </ServiceContent>
      </ServiceSection>

      <ServiceSection id="construction-management">
        <SectionTitle title="Construction Management" />
        <ServiceContent>
          <ServiceImage
            src="https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1931&q=80"
            alt="Construction Management"
          />
          <ServiceDescription>
            <p>
              Our Construction Management services provide comprehensive oversight of construction projects, ensuring they are completed safely, on time, and within budget. We serve as your representative throughout the construction process, protecting your interests and ensuring quality outcomes.
            </p>
            <p>
              Our Construction Management services include:
            </p>
            <ul>
              <li>Pre-construction planning and value engineering</li>
              <li>Bid package preparation and contractor selection</li>
              <li>Schedule management and coordination</li>
              <li>Cost control and change order management</li>
              <li>Quality assurance and quality control</li>
              <li>Safety program implementation and monitoring</li>
              <li>Project documentation and reporting</li>
              <li>Closeout and warranty management</li>
            </ul>
            <p>
              With our experienced team managing your construction project, you can focus on your core business while we ensure that your project is delivered successfully.
            </p>
          </ServiceDescription>
        </ServiceContent>
      </ServiceSection>
    </PageContainer>
  );
};

export default ServicesPage; 